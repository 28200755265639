import React, { useState } from "react";
import { Box, Heading } from "rebass/styled-components";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form } from "../../common/form";
import { FormButton } from "../merchants/application/style";

export const schema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required("Email is required"),
  password: yup.string().required("Password is required"),
});

const LoginForm = ({ login }) => {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");

  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async form => {
    try {
      setSubmitting(true);
      setError("");
      await login(form);
      reset();
    } catch (e) {
      console.log({ e });
      setError(e?.message || "An error occured");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box maxWidth="44rem" mx="auto">
      <Heading as="h2" mb="3rem">
        Login
      </Heading>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group alternate>
          <Form.Label htmlFor="email">
            E-mail address <span className="required">*</span>
          </Form.Label>
          <Form.Input
            error={errors.email}
            id="email"
            name="email"
            placeholder="example@mail.com"
            type="email"
            ref={register}
          />
          {errors.email ? <Form.Error error={errors.email.message} /> : null}
        </Form.Group>

        <Form.Group alternate>
          <Form.Label htmlFor="password">
            Password <span className="required">*</span>
          </Form.Label>
          <Form.Input
            error={errors.password}
            id="password"
            name="password"
            placeholder="*******"
            type="password"
            ref={register}
          />
          {errors.password ? <Form.Error error={errors.password.message} /> : null}
        </Form.Group>

        <Form.Group alternate>
          <FormButton isLoading={submitting} onClick={handleSubmit(onSubmit)} type="submit" variant="form">
            Submit
          </FormButton>

          {error ? (
            <Box mt="10px">
              <Form.Error error={error} />
            </Box>
          ) : null}
        </Form.Group>
      </Form>
    </Box>
  );
};

export default LoginForm;
