import React, { useEffect, useState } from "react";
import firebase from "gatsby-plugin-firebase";
import { Box, Button, Heading, Text } from "rebass/styled-components";
import styled from "styled-components";

const Dashboard = () => {
  const [count, setCount] = useState({
    agent: 0,
    aggregators: 0,
  });

  useEffect(() => {
    firebase
      .firestore()
      .collection("forms")
      .doc("minipos")
      .onSnapshot(snapshot => {
        if (snapshot.exists) {
          const { count: snapshotCount = {} } = snapshot.data();
          setCount(v => ({ ...v, ...snapshotCount }));
        }
      });
  }, []);

  const logout = () => {
    firebase.auth().signOut();
  };

  return (
    <Box>
      <Heading as="h2" mb="6rem" style={{ textAlign: "center", textDecoration: "underline" }}>
        OPay Mini POS Application Form Analytics Dashboard
      </Heading>

      <StyledGrid>
        <StyledSpanHeadings>Action</StyledSpanHeadings>
        <StyledSpanHeadings>Source</StyledSpanHeadings>
        <StyledSpanHeadings>Count/Conversions</StyledSpanHeadings>

        <StyledSpan>Sign-up</StyledSpan>
        <StyledSpan>Website</StyledSpan>
        <StyledSpan>
          <StyledText>Merchants:</StyledText> {count.agent || 0}
          <br />
          <StyledText>Aggregators:</StyledText> {count.aggregator || 0}
        </StyledSpan>
      </StyledGrid>

      <Box display="flex" style={{ justifyContent: "flex-end" }}>
        <Button mt={50} onClick={logout}>
          Logout
        </Button>
      </Box>
    </Box>
  );
};

export default Dashboard;

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border-top: 1px solid #000;
  border-right: 1px solid #000;
`;

const StyledSpan = styled.span`
  border-left: 1px solid #000;
  border-bottom: 1px solid #000;
  padding: 7px;
`;

const StyledSpanHeadings = styled(StyledSpan)`
  font-weight: bold;
  text-transform: uppercase;
`;

const StyledText = styled.span`
  color: ${({ theme }) => theme.colors.secondary};
  font-weight: bold;
`;
