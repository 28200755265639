import React, { useEffect, useState } from "react";
import firebase from "gatsby-plugin-firebase";
import { Box, Flex, Text } from "rebass/styled-components";
import LoginForm from "./LoginForm";
import Dashboard from "./Dashboard";
import { Container } from "../../global";

const Analytics = () => {
  const [state, setState] = useState({
    loading: true,
    user: null,
  });

  const login = ({ email, password }) => {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      if (user) {
        setState({
          loading: false,
          user: user,
        });
      } else {
        setState({
          loading: false,
          user: null,
        });
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  if (state.loading)
    return (
      <Flex py="18rem" alignItems="center" justifyContent="center">
        <Text variant="body">loading ...</Text>
      </Flex>
    );

  return (
    <Box py="18rem">
      <Container>{state.user ? <Dashboard /> : <LoginForm login={login} />}</Container>
    </Box>
  );
};

export default Analytics;
