import React from "react";
import Layout from "../components/common/layout/layout";
import Navigation from "../components/common/navigation/navigation";
import Footer from "../components/sections/footer";
import Analytics from "../components/sections/analytics";

const Adb = () => {
  return (
    <Layout>
      <Navigation background="white" />
      <Analytics />
      <Footer />
    </Layout>
  );
};

export default Adb;
